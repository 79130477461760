.landing-form{
    margin-left: 32px;
    margin-right: 32px;
}
.landing-button {
    margin: 5px !important;
}


@media screen and (max-width: 500px) {    
    .landing-form{
        margin-left: 32px;
        margin-right: 32px;
        display: flex;
        flex-direction: column;
    }
    .landing-button {
        margin: 5px 0 0 !important;
    }
}

.borderRadius {
    border-radius: 10px;
}

.popupLanding {
    display: none;
}
