
.leaflet-container {
    min-height: 50vh;
	width: 80vw;
	margin: auto;
	position: relative;
	border-radius: 40px;
}
.city-list{
	width: 80vw;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	margin: auto;
	

}
.city-find{
	// margin: 16px;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.09);
	margin: 8px;
	padding: 8px;
	 
}

.map-overlay{
	width: 100%;
	height: 100%;
	position: absolute;
 	opacity: 0.5;
	z-index: 10000;
 

}
.city-map{
	z-index: 10000;
 	width: 100px;
	height: 30%;
	text-align: center;
 	justify-content: center;
	align-items: center;
	background-color: black;
	margin: 16px;
	display: flex;
	position: absolute;
	opacity: 0.7;
 }
.left-city1{
	border-radius: 40px 0 0 0;

	left: 0;
	top: 0;
}
.left-city2{
	border-radius: 0 0  0 40px ;

	left: 0;
	bottom: 0;
}
.right-city1{
	border-radius: 0 40px  0 0 ;

	right: 0;
	top: 0;
}
.right-city2{
	border-radius: 0 0  40px 0 ;

	right: 0;
	bottom: 0;
}