  .footer-container{
	// display: flex;
	// flex-direction: column;
	// bottom: 0;
	// height: 80vh;
	display: flex;
justify-content: center;
 align-items: center;
flex-direction: column;
 }
.footer-bottom{
  
	display: flex;
	justify-content: center;
	 
	flex-direction: column;
position: absolute;;
  bottom: 5vh;

 }
 
.footer-dotsContainer {
	display: flex;
	justify-content: center;
	margin: 20px;
}

.footer-dots {
	height: 15px;
	width: 15px;
	background-color: #3F9C61;
	border-radius: 15px;
}
 
// .footer-title {
// 	margin-bottom: 0;
// }

.footer-separator {
	height: 2px;
	background-color: #fff;
	border-radius: 5px;
	margin: 15px 0 30px;
}

.footer-legal {
	font-weight: bold;
	padding: 64px;
}

.footer-logo{
	height: 200px;
	margin: auto;
}
.footer-section{
	top: 20vh;
	position: absolute;
}
.progonline-sigle{
	position: absolute;
	left: 32px;
	bottom: 32px;
}
@media screen and (max-width: 500px) {    
	.progonline-sigle{
		position: absolute;
		left: 8px;
		bottom: 8px;
	}
}