.services {
  text-align: center;
}

.services:hover h2 {
  display: inline-block;
  text-transform: uppercase;
  border-bottom: 4px solid #3498db;
  font-size: 20px;
  padding-bottom: 10px;
  margin-top: 40px;
}

.cen {
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
  padding: 20px;
}

.service {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% / 3);
  margin: 0 -2px;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.4s;
}

.service:hover {
  background: #ddd;
  color: black;
}

.service i {
  color: #3498db;
  font-size: 34px;
  margin-bottom: 30px;
}

.service h2 {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  margin: 10px 0;
}

.service p {
  color: rgb(153, 145, 145);
  height: 120px;
  width: 100%;
  text-align: center;
}
.service:hover p{
  color: rgb(66, 66, 66);
}

@media screen and (max-width: 800px) {
  .service {
    width: 50%;
  }

  .service-block {
    flex-direction: column;
  }
  
}

@media screen and (max-width: 500px) {
  .service {
    width: 100%;
  }
}

.icon-services {
  height: 64px;
  width: 64px;

}

.text-service {
  padding-top: 10px;
}

@media screen and (min-width: 500px) {
 
.service-details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.service-details h1{
  text-transform: uppercase;
  padding: 0px;
  margin: 0px;
}

.service-details-web{
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.service-details-web h1{
  padding: 0px;
  margin: 0px;
  text-transform: uppercase;
}

.text-service-overlay {
  background-color: black;
  position: absolute;
  opacity: 0.6;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}



.text-service-content p{
  font-size: 3rem;
}


.title-service {
  font-size: 4em;
  text-align: left;
}


.background-service-details {
  background-position: bottom !important;
  background-size: inherit !important;
  background-repeat: no-repeat !important;
}

.background-service-details-web {
  background-position: bottom  !important;
  background-size: inherit !important;
  background-repeat: no-repeat !important;
}

.background-service-details-consulting {
  background-position: 50% 400% !important;
  background-size: inherit !important;
  background-repeat: no-repeat !important;
}

.popup-service {
  z-index: 101;
  background-color: black!important;
}
.background-service-details-ux{
  background-position: center !important;

}
.ux-popup-content{
  position: absolute;
  /* bottom: 5vh; */
  /* max-width:80% ;
  right: 10vh; */
  bottom: 5vh;
  width: 80%;
  text-align: left;
 
}}
.consulting-popup-content {
  /* top:20vh; */
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.button-change-consulting{
  width: 200px;
}
.title-service::first-letter {
  color: green;
}

.text-service {
  position: relative;
  text-align: left;
}

.service-block {
  justify-content: space-evenly;
  display: flex;
}

.text-service-content {
  padding-left: 20px;
}

.close-popup-change{
  position: relative!important;
  color:darkgray!important;
 font-size: 1.5em!important;
 page-break-after: 0!important;
 opacity: 1!important;
}