.grid {
    display: flex;
    flex-wrap: wrap; 
    margin: 0;
    padding: 0;
    justify-content: center;
}

a, a:hover {
    text-decoration: none;
}

.item {
    margin: 0px auto;
}

h2,h3,h4,h5 {
    margin: 0px;
}

.item {
    z-index: 2222;
    width: 200px;
    height: 200px;
    border-radius: 67% 0;
    margin: 75px 0 75px 175px;
    overflow: hidden;
    margin: auto;
    transform: translateX(10px);
}

.text-card-team {
    padding: 2em;
    text-align: center;
    padding:20px;
    width: 200px;
}

.item .text-card-team figcaption {
    position: relative;
    z-index: 1;
}

.item .text-card-team a {
    color: rgba(0, 0, 0, 0.70);
}

.item .text-card-team p {
    color: white;
    font-weight: 300;
}

.leaf {
    width: 150px;
    height: 150px;
    border-radius: 67% 0;
    background: #F3AC3C;
    margin: 75px 0 75px 175px;
    transform: rotate(-45deg); 
  }

.card-team{
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.09);
    margin: 10px;
    padding: 10px;
}

.div-icons-profil{
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}

.icon-container {
    border: 1px solid white;
    padding: 10px;
    border-radius: 100%;
    margin-right: 20px;
}

@media screen and (max-width: 500px) {
    .item {
        width: 150px;
        height: 150px;
    }
}

a:visited {
    fill:white;
}

a:hover, a:active, a:focus {
    fill:white;
}
