.stepper{
    /* width: 100vw;
    height: 80vh; */
    /* background-color:rgba(51, 170, 51, .4); */
    /* position: fixed; */
    /* top:20vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    /* left:0; */
}
.form-stepper{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 60%;
    width: 100%;
    justify-content: space-around;
}
.stepper-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* justify-content: space-between; */
    min-width: 200px;
    margin: 16px;

}
.button-div{
    width: 33%;
}
/* revoir */
#g-recaptcha-response{
    display: none;
}

.borderRadius {
    border-radius: 10px;
}

.button-send {
    margin-top: 20px;
    width: 296px;
}

.popupForm {
    display: none;
}
